/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { H4Title } from 'components/H4Title';
import { P } from 'components/P';
import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Text } from 'components/Text/Text';
import { DisckoHeader } from 'components/Sections/Header/DisckoHeader';
import { Language } from 'utils/languages';
import { Button } from 'components/Button/Button';
import { RichText } from 'components/RichText/RichText';
import { H3Title } from 'components/H3Title';
import { H2Title } from 'components/H2Title';

export function PricingPage(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const [isCard1Hovered, setIsCard1Hovered] = useState<boolean>(false);
  const [isCard2Hovered, setIsCard2Hovered] = useState<boolean>(false);
  const [isCard3Hovered, setIsCard3Hovered] = useState<boolean>(false);

  return (
    <div className="w-full h-full flex">
      {isLargeScreen && (
        <div
          className={concatClassNames(
            'flex flex-col h-screen bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 lg:gap-4',
          )}
        >
          <DisckoHeader language={Language.FR} />
          <div
            className={concatClassNames(
              'flex flex-row gap-12 h-full w-full justify-center items-center',
              isLargeScreen ? 'py-6' : 'py-4',
              isLargeScreen ? 'flex-row' : 'flex-col',
              'items-center align-middle',
            )}
          >
            <div className="items-center align-middle flex flex-col gap-8 w-[50%] mx-20">
              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    content: "Discko, la simplicité jusqu'au tarif : ",
                    size: 'h2title',
                    position: 'center',
                  },
                  {
                    contentType: 'span',
                    content: 'une solution complète ',
                    size: 'h2title',
                    weight: 'normal',
                    position: 'center',
                    relative: true,
                  },
                  {
                    contentType: 'span',
                    content: 'à un tarif unique.',
                    size: 'h2title',
                    weight: 'bold',
                    position: 'center',
                    relative: true,
                  },
                ]}
              />
              <H3Title
                position="left"
                weight="bold"
                text="Profitez d'un abonnement mensuel sans engagement pour une prospection continue et efficace, à votre image. "
              ></H3Title>
              {/* <img
                src="/imgs/surligneur_bleu.png"
                className={concatClassNames(
                  'absolute opacity-50',
                  'xl:left-80 xl:top-52 xl:rotate-0 xl:w-[280px] xl:h-[50px]',
                  'lg:-right-2 lg:-top-6 lg:rotate-6 lg:w-[320px] ',
                  'right-0 top-8  rotate-2 w-[300px]',
                )}
              /> */}
            </div>
            <div className="flex flex-col items-center gap-4 w-[50%]">
              <div
                className={concatClassNames(
                  'flex items-center align-items justify-between gap-8 mx-10 lg:mx-0 lg:px-10',
                  isLargeScreen ? 'flex-row' : 'flex-col',
                )}
              >
                <div
                  onMouseEnter={() => {
                    setIsCard3Hovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsCard3Hovered(false);
                  }}
                >
                  <div
                    className={concatClassNames(
                      'bg-blue-400 text-white',
                      'justify-around',
                      'flex flex-col',
                      ' items-center align-middle',
                      ' rounded-lg',
                      'p-10 ',
                    )}
                  >
                    <div className="flex flex-row items-center gap-1">
                      <Text content="60€" size="4xl" weight="bold" color="white" />
                      <p className="text-base text-white"> HT/mois</p>
                    </div>

                    <div className="flex flex-col justify-start text-lg lg:text-xl font-circularStd-medium font-bold w-full p-4">
                      <ul className="flex flex-col gap-4">
                        <li className="flex flex-row items-center">
                          <img
                            src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                            className="h-12 w-12"
                          ></img>
                          {'Expressions de besoin illimitées'}
                        </li>
                        <li className="flex flex-row items-center">
                          <img
                            src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                            className="h-12 w-12"
                          ></img>
                          {" Jusqu'à 10 administrateurs."}
                        </li>
                        <li className="flex flex-row items-center">
                          <img
                            src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                            className="h-12 w-12"
                          ></img>
                          {' Personnalisation du formulaire.'}
                        </li>
                      </ul>
                    </div>
                    <Button
                      text="Souscrivez dès aujourd'hui"
                      textColor="white"
                      backgroundColor="blue-300"
                      horizontalPadding="4"
                      href="/contact"
                      onHover="hover:bg-blue-200 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLargeScreen && (
        <div
          className={concatClassNames(
            'flex flex-col w-full h-full bg-gradient-to-r from-gradient7-from to-gradient7-to gap-3 ',
          )}
        >
          <DisckoHeader language={Language.FR} />
          <div className={concatClassNames('items-center align-middle flex flex-col gap-12 text-center w-full p-4')}>
            <div>
              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    content: "Discko, la simplicité jusqu'au tarif : ",
                    size: 'h3title',
                    position: 'center',
                  },
                  {
                    contentType: 'span',
                    content: 'une solution complète ',
                    size: 'h3title',
                    weight: 'normal',
                    position: 'center',
                  },
                  {
                    contentType: 'span',
                    content: 'à un tarif unique.',
                    size: 'h3title',
                    weight: 'bold',
                    position: 'center',
                  },
                ]}
              />
            </div>
            <div
              className={concatClassNames(
                'flex flex-col gap-12 w-full justify-center items-center',
                'flex-col',
                'items-center align-middle',
                'p-4',
              )}
            >
              <div
                onMouseEnter={() => {
                  setIsCard3Hovered(true);
                }}
                onMouseLeave={() => {
                  setIsCard3Hovered(false);
                }}
              >
                <div
                  className={concatClassNames(
                    'bg-blue-400 text-white',
                    'justify-around',
                    'flex flex-col',
                    ' items-center align-middle',
                    ' rounded-lg',
                    'w-fit',
                    'p-4',
                  )}
                >
                  <div className="flex flex-row items-center gap-1 w-fit">
                    <Text content="60€" size="4xl" weight="bold" color="white" />
                    <p className="text-base text-white"> HT/mois</p>
                  </div>

                  <div
                    className={concatClassNames(
                      'flex flex-col',
                      ' justify-start text-lg font-circularStd-medium font-bold w-fit p-4',
                    )}
                  >
                    <ul className="flex flex-col gap-4">
                      <li className="flex flex-col items-center">
                        <img
                          src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                          className="h-12 w-12"
                        ></img>
                        {'Expressions de besoin illimitées'}
                      </li>
                      <li className="flex flex-col items-center">
                        <img
                          src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                          className="h-12 w-12"
                        ></img>
                        {" Jusqu'à 10 administrateurs."}
                      </li>
                      <li className="flex flex-col items-center w-full">
                        <img
                          src={isCard3Hovered ? '/imgs/validationWithoutBackground.gif' : '/imgs/validation.png'}
                          className="h-12 w-12"
                        ></img>
                        {' Personnalisation du formulaire.'}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={concatClassNames('flex flex-col gap-4 items-center')}>
                <H4Title
                  position="center"
                  weight="bold"
                  text="Profitez d'un abonnement mensuel sans engagement pour une prospection continue et efficace, à votre image. "
                ></H4Title>
                <Button
                  text="Souscrire"
                  size="h4"
                  textColor="white"
                  backgroundColor="blue-300"
                  horizontalPadding="4"
                  href="/contact"
                  onHover="hover:bg-blue-200 hover:border-white focus-visible:outline focus-visible:outline-2 hover:text-white"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
