import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import React, { useRef, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import concatClassNames from 'utils/classNames';
import { type Swiper as SwiperType } from 'swiper';
import { useFullscreen } from 'react-use';
import uuid from 'react-uuid';
import { H4Title } from 'components/H4Title';

interface Step {
  index: number;
  description: string;
  detail?: JSX.Element;
  source: string;
  orientation: 'left' | 'right';
}
export function DisckoDescription(): JSX.Element {
  const sliderRef: React.MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  const steps: Step[] = [
    {
      index: 1,
      description: 'Votre client décrit son projet en 500 caractères',
      detail: (
        <div className="flex flex-col items-start w-fit gap-3">
          <div className=" justify-start flex flex-col gap-4 ">
            <P
              position="justify"
              text="Les besoins de vos clients sont variés et complexes, et ils peinent à les formuler d'une manière structurée, complète et concise."
            ></P>

            <ul
              className={concatClassNames(
                'list-disc  list-inside',
                // 'bg-white',
                'justify-start w-full',
                ' border-0 border-black rounded-xl indent-4 lg:indent-20',
                'flex flex-col',
                'gap-2 py-2',
                'font-bold',
                'text-[14px] lg:text-[16px] xl:text-[16px] font-circularStd-Light',
              )}
            >
              <li>Création et développement de sites web</li>
              <li>{`Organisation d'événement`}</li>
              <li>{`Installation de matériel`}</li>
              <li>{`Décoration et aménagement d'un espace`}</li>
              <li>etc.</li>
            </ul>
            <P
              position="justify"
              text="Quel que soit le type de projet, Discko simplifie l’expression de vos besoins, en évitant des demandes trop longues et complexes ou trop brèves et incomplètes."
            ></P>
          </div>
        </div>
      ),
      source: '/imgs/step_1.mp4',
      orientation: 'left',
    },
    {
      index: 2,
      description: 'Discko analyse son besoin et lui suggère des questions personnalisées.',
      detail: (
        <div className="flex flex-col items-start w-fit gap-3">
          <div className=" justify-start flex flex-col gap-4 ">
            <P
              position="justify"
              text="Notre algorithme d'IA guide vos clients vers une expression claire et précise de leurs besoins."
            ></P>
            <P
              position="justify"
              text="Discko les accompagne dans leur travail de formulation, en leur posant des questions simples, précises, et personnalisées à leur projet."
            ></P>
            <P
              position="justify"
              text="Cela les aide à verbaliser ce qu'ils ont dans la tête, les rassure et les aide à se projeter avant même d'avoir rencontré votre équipe."
            ></P>
            <P
              position="justify"
              text="Nos suggestions de réponse les guident et vous ouvrent des portes pour la suite de l'accompagnement."
            ></P>
          </div>
        </div>
      ),
      source: '/imgs/step_2.mp4',
      orientation: 'left',
    },
    {
      index: 3,
      description: 'Obtenez une synthèse standardisée du besoin du client',
      detail: (
        <div className="flex flex-col items-start w-fit gap-3">
          <div className=" justify-start flex flex-col gap-4 ">
            <P
              position="justify"
              text="Discko analyse l'ensemble des réponses formulées par le client et génère une expression de besoin complète et synthétique, structurée en plusieurs sections, comme par exemple :"
            ></P>
            <ol
              className={concatClassNames(
                'list-decimal  list-inside',
                // 'bg-white',
                'justify-start w-full',
                ' border-0 border-black rounded-xl indent-4 lg:indent-20',
                'flex flex-col',
                'gap-2 py-2',
                'font-bold',
                'text-[14px] lg:text-[16px] xl:text-[16px] font-circularStd-Light',
              )}
            >
              <li>Contexte</li>
              <li>Objectifs</li>
              <li>Utilisateurs cibles</li>
              <li>Facteurs clefs de succès</li>
              <li>Contraintes (budgétaires, juridiques...)</li>
            </ol>
            <P position="justify" text="Une fois validée, cette synthèse vous est transmise automatiquement."></P>
            <P
              position="justify"
              text="Gagnez un temps précieux avec des briefs clients structurés et exploitables immédiatement."
            />
          </div>
        </div>
      ),

      source: '/imgs/step_3.mp4',
      orientation: 'left',
    },
  ];
  return (
    <div>
      {isLargeScreen ? (
        <>
          <div>
            <Swiper
              slidesPerView={1}
              centeredSlides={true}
              slideToClickedSlide={true}
              spaceBetween={20}
              pagination={{
                clickable: true,
                enabled: true,
              }}
              loop={false}
              style={{
                '--swiper-pagination-color': '#000',
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="flex h-fit"
              wrapperClass="flex lg:flex-row flex-col"
              onSwiper={(it: SwiperType) => (sliderRef.current = it)}
              autoplay={{
                delay: 10000, // Défilement autoatique après 5 secondes
                disableOnInteraction: false, // Garde l'autoplay actif après une interaction
              }}
            >
              {steps.map((step, index) => {
                return (
                  <SwiperSlide key={uuid()}>
                    <div className="flex flex-row w-full items-center bg-white drop-shadow-lg lg:h-[540px] p-6">
                      <div className="w-[100%] flex flex-col items-start xl:gap-4 lg:gap-4  m-20 bg-transparent">
                        <H3Title text={`${step.index}. ${step.description}`} position="left" />
                        {step.detail}
                      </div>

                      <div className="w-[51%] items-center justify-center align-middle">
                        <video
                          key={step.index}
                          autoPlay
                          muted
                          loop
                          className="video w-full lg:w-[90%] xl:w-[65%] rounded-lg "
                        >
                          <source src={step.source} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </>
      ) : (
        <>
          <div>
            <Swiper
              slidesPerView="auto"
              centeredSlides={true}
              slideToClickedSlide={true}
              spaceBetween={20}
              pagination={{
                clickable: true,
                enabled: true,
              }}
              style={{
                '--swiper-pagination-color': '#000',
              }}
              modules={[Pagination, Autoplay]}
              className="flex h-fit"
              wrapperClass="flex flex-row"
              onSwiper={(it: SwiperType) => (sliderRef.current = it)}
            >
              {steps.map((step, index) => {
                return (
                  <SwiperSlide key={uuid()}>
                    <div className="flex flex-col  w-full items-center justify-center bg-white drop-shadow-lg h-[950px]">
                      <div className="w-[40%] items-center justify-center align-middle">
                        <video
                          autoPlay
                          muted
                          loop
                          className="video w-full lg:w-[90%] rounded-lg "
                          onClick={(e) => {
                            const videoElement = e.currentTarget;
                            if (videoElement.requestFullscreen) {
                              videoElement.requestFullscreen().catch((err) => {
                                console.log(err);
                              });
                            }
                          }}
                        >
                          <source src={step.source} type="video/mp4" />
                        </video>
                      </div>
                      <div className="w-[100%] flex flex-col gap-4  px-8 pb-12 bg-transparent">
                        <H3Title text={`${step.index}. ${step.description}`} position="left" weight="bold" />
                        {step.detail}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}
